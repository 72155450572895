<template>
  <v-container fluid class="wLightGray secondary-container">
    <div class="secondary-container" :class="$vuetify.breakpoint.lgAndUp ? 'line' : ''">
      <router-link to="/mis-eventos" class="font-weight-bold">
        <v-icon> mdi-chevron-left </v-icon>
        <span> Volver a mis eventos </span>
      </router-link>
    </div>
    <v-sheet class="rounded-xl mb-15 mb-md-0 mt-lg-0 mt-4 secondary-container">
      <v-row :class="$vuetify.breakpoint.lgAndUp ? 'container-85-vh' : 'container-75-vh'
        ">
        <v-col cols="12" class="px-8 pt-5">
          <h2 class="wDarkerBlue--text font-weight-black">
            Comencemos a configurar tu curso o evento
          </h2>
        </v-col>
        <v-col cols="12">
          <v-row class="justify-center">
            <v-col cols="12" md="8" lg="6">
              <v-card tile elevation="0" class="align--transform pb-6">
                <v-form v-model="validCreateEventForm" ref="createEventForm">
                  <v-container>
                    <fieldset v-if="step === 1">
                      <v-col cols="12">
                        <span class="font-weight-bold">¿Qué nombre le quieres dar?</span>
                      </v-col>
                      <v-col cols="12">
                        <span>Ponle nombre que capte la atención de tu
                          audiencia</span>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field label="Nombre de evento" v-model="form.name"
                          placeholder="Ejemplo. Taller de finanzas personales" filled rounded
                          :rules="eventNameRules"></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12">
                        <span>
                          Asignale un código a tu evento
                        </span>
                      </v-col> -->
                      <!-- <v-col cols="12">
                        <v-text-field label="Código del evento" v-model="form.eventCode" filled rounded></v-text-field>
                      </v-col> -->
                    </fieldset>
                    <fieldset v-if="step === 2">
                      <v-col cols="12">
                        <span class="font-weight-bold">¿De qué se trata tu evento?</span>
                      </v-col>
                      <v-col cols="12">
                        <span>Agrega una descripción breve pero completa</span>
                      </v-col>
                      <v-col cols="12">
                        <tiptap-vuetify v-model="form.description" :extensions="extensions" placeholder="Descripción"
                          :toolbar-attributes="{
                            color: 'primary',
                            rounded: 't-lg',
                            dark: true,
                          }" :card-props="{
                            elevation: '0',
                            rounded: 'lg',
                            color: '#f0f0f0',
                          }" />
                      </v-col>
                    </fieldset>
                    <fieldset v-if="step === 3">
                      <v-row class="mb-5">
                        <v-col cols="12">
                          <span class="font-weight-bold">¿Qué día es tu evento?</span>
                        </v-col>
                        <v-col cols="12">
                          <span>Ingresa la fecha y hora de tu evento</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="initialDateFormatted" label="Fecha de inicio *" persistent-hint
                                readonly v-on="on" required :rules="startDateRules" :disabled="form.recurrent" filled
                                rounded></v-text-field>
                            </template>
                            <v-date-picker v-model="form.dateConfiguration.startDate" no-title
                              @input="dateMenu1 = false" :min="todayDate" locale="es"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="finalDateFormatted" label="Fecha de fin *" persistent-hint readonly
                                v-on="on" required :rules="endDateRules" :disabled="form.recurrent" filled
                                rounded></v-text-field>
                            </template>
                            <v-date-picker v-model="form.dateConfiguration.endDate" no-title @input="dateMenu2 = false"
                              :min="form.dateConfiguration.startDate" locale="es"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-menu ref="menu1" v-model="timeMenu1" :close-on-content-click="false" :nudge-right="40"
                            :return-value.sync="form.dateConfiguration.startTime
                              " transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="form.dateConfiguration.startTime" label="Hora de inicio *" readonly
                                v-on="on" required :rules="startTimeRules" :disabled="form.recurrent" filled
                                rounded></v-text-field>
                            </template>
                            <v-time-picker v-if="timeMenu1" v-model="form.dateConfiguration.startTime" @click:minute="
                              $refs.menu1.save(
                                form.dateConfiguration.startTime
                              )
                              "></v-time-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-menu ref="menu2" v-model="timeMenu2" :close-on-content-click="false" :nudge-right="40"
                            :return-value.sync="form.dateConfiguration.endTime" transition="scale-transition" offset-y
                            max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="form.dateConfiguration.endTime" label="Hora de fin *" readonly
                                v-on="on" required :rules="endTimeRules" :disabled="form.recurrent" filled
                                rounded></v-text-field>
                            </template>
                            <v-time-picker v-if="timeMenu2" v-model="form.dateConfiguration.endTime" @click:minute="
                              $refs.menu2.save(form.dateConfiguration.endTime)
                              "></v-time-picker>
                          </v-menu>
                        </v-col>
                        <!--<v-col cols="12">
                          <v-autocomplete
                            v-model="form.dateConfiguration.timeZone"
                            :items="timeZones"
                            label="Huso horario"
                            required
                            solo
                            background-color="wLightGray"
                            :disabled="form.recurrent"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" class="mb-5">
                          <v-checkbox
                            v-model="form.recurrent"
                            label="Evento recurrente (Sin fechas definidas)."
                            color="accent"
                          ></v-checkbox>
                        </v-col>-->
                      </v-row>
                    </fieldset>
                    <fieldset v-if="step === 4">
                      <v-row>
                        <v-col cols="12">
                          <span class="font-weight-bold">¿Qué modalidad aplica para tu evento?</span>
                        </v-col>
                        <v-col cols="12">
                          <span>Selecciona la modalidad que aplique para tu
                            evento.</span>
                        </v-col>
                        <v-col cols="12">
                          <v-select v-model="form.eventModality" :items="modalityCategories" label="Modalidad" filled
                            rounded required @change="updateTicketModality()" :rules="modalityRules">
                            <template slot="selection" slot-scope="data">
                              <span>{{ data.item.item }}</span>
                            </template>
                            <template slot="item" slot-scope="data">
                              <span>{{ data.item.item }}</span>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </fieldset>

                    <fieldset v-if="step === 5">
                      <v-row>
                        <v-col cols="12">
                          <span class="font-weight-bold">¿Qué tipo de evento es?*</span>
                        </v-col>
                        <v-col cols="12">
                          <span>Selecciona el tipo que aplique para tu
                            evento.</span>
                        </v-col>
                        <v-col cols="12">
                          <v-select v-model="form.type" :items="eventType" label="Tipo" filled rounded
                            :rules="eventTypeRules"></v-select>
                        </v-col>
                      </v-row>
                    </fieldset>

                    <fieldset v-if="step === 6">
                      <v-row>
                        <v-col cols="12">
                          <span class="font-weight-bold">¿Cuál será la ubicación de tu evento?</span>
                        </v-col>
                        <v-col cols="12">
                          <span>Ingresa los datos de la ubicación de tu
                            evento.</span>
                        </v-col>

                        <v-col v-if="form.eventModality != 'VIRTUAL'" cols="12" class="mb-5">
                          <div
                            class="v-input theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--rounded pb-6"
                            v-if="!manualAddressEntry">
                            <div class="v-input__control d-flex">
                              <div class="v-input__slot">
                                <div class="v-input__prepend-inner">
                                  <div class="v-input__icon v-input__icon--prepend-inner">
                                    <i aria-hidden="true"
                                      class="v-icon notranslate fas fa-map-marker-alt theme--light"></i>
                                  </div>
                                </div>
                                <div class="v-text-field__slot">
                                  <label for="input-274" class="v-label theme--light" style="
                                      left: 0px;
                                      right: auto;
                                      position: absolute;
                                    "></label>
                                  <gmap-autocomplete placeholder="Ubicación del recinto *" style="margin-top: 15px"
                                    @place_changed="saveAddress" :value="place"></gmap-autocomplete>
                                </div>
                              </div>
                            </div>

                            <v-tooltip top max-width="350px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" dark v-on="on" class="ml-2 mt-4">mdi-help-circle</v-icon>
                              </template>
                              <span>Para llenar este campo correctamente es
                                necesario seleccionar alguna de las opciones que
                                se sugieren al momento de estar escribiendo la
                                ubicación.</span>
                            </v-tooltip>
                          </div>

                          <v-text-field v-if="manualAddressEntry" v-model="manualAddress" @input="validateFields"
                            :value="place" label="Dirección del evento" placeholder="Ingresa la dirección" filled
                            rounded></v-text-field>

                          <v-checkbox v-model="manualAddressEntry" label="Ingresar dirección manualmente"
                            @change="resetFields"></v-checkbox>
                          <div v-if="place == '' || place == null" class="v-text-field__details">
                            <div class="v-messages theme--light error--text" role="alert">
                              <div class="v-messages__wrapper">
                                <div class="v-messages__message mt-2">
                                  La ubicación del recinto es obligatoria.
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-col>
                        <v-col v-if="form.eventModality != 'VIRTUAL'" cols="12" md="6">
                          <v-text-field v-model="form.presentialEventConfiguration.country" label="País"
                            placeholder="País en donde será tu evento." filled rounded :readonly="!manualAddressEntry"
                            @input="validateFields"></v-text-field>
                        </v-col>
                        <v-col v-if="form.eventModality != 'VIRTUAL'" cols="12" md="6">
                          <v-text-field v-model="form.presentialEventConfiguration.city" label="Ciudad"
                            placeholder="Ciudad en donde será tu evento." filled rounded :readonly="!manualAddressEntry"
                            @input="validateFields"></v-text-field>
                        </v-col>
                        <v-col v-if="form.eventModality != 'PRESENTIAL'" cols="12">
                          <v-text-field v-model="form.virtualEventConfiguration.url"
                            label="Liga de acceso para evento en línea" placeholder="Ingresa la liga de acceso." filled
                            rounded :rules="linkRules"></v-text-field>
                        </v-col>
                      </v-row>
                    </fieldset>

                    <fieldset v-if="step === 7">
                      <v-row>
                        <v-col cols="12">
                          <span class="font-weight-bold">¿Deseas agregar una liga para la evaluación de cursos?</span>
                        </v-col>

                        <v-col cols="12">
                          <v-row>
                            <v-col cols="12">
                              <v-switch v-model="form.evaluationConfiguration.hasEvaluationUrl"
                                label="Agregar liga para la evaluación de cursos" color="primary"></v-switch>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col v-if="form.evaluationConfiguration.hasEvaluationUrl" cols="12">
                          <v-text-field v-model="form.evaluationConfiguration.url"
                            label="Liga de acceso para la evaluación de cursos" placeholder="Ingresa la liga de acceso."
                            filled rounded :rules="linkRules"></v-text-field>
                        </v-col>
                      </v-row>
                    </fieldset>

                    <fieldset v-if="step === 8">
                      <v-row>
                        <v-col cols="12">
                          <span class="font-weight-bold">¿Con qué amenidades contará tu evento?</span>
                        </v-col>
                        <v-col cols="12">
                          <span>Selecciona las amenidades con las que cuenta tu
                            evento.</span>
                        </v-col>
                        <v-col cols="12">
                          <v-row class="mx-1">
                            <v-col cols="12" sm="6" md="4" xl="3" v-for="(amenity, index) in amenities.filter(
                              (amenity) =>
                                form.eventModality == amenity.eventType ||
                                amenity.eventType == 'HYBRID' ||
                                form.eventModality == 'HYBRID'
                            )" :key="index">
                              <v-row>
                                <v-switch color="primary" v-model="form.amenities" :value="amenity.name"
                                  :label="amenity.name"></v-switch>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </fieldset>

                    <fieldset v-if="step === 9">
                      <v-col cols="12">
                        <span class="font-weight-bold">
                          Ayuda a tus asistentes a encontrar tu evento
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <span>
                          Agrega palabras clave que ayuden a tus asistentes a
                          encontrar tu evento.
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox hide-selected label="Seleccione las etiquetas pertinentes" multiple
                          background-color="#f9fafc" persistent-hint hide-details small-chips filled
                          :items="previousTags" v-model="form.tags">
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Presiona
                                  <kbd>enter</kbd> para crear una nueva
                                  etiqueta.
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-combobox>
                      </v-col>
                    </fieldset>

                    <fieldset v-if="step === 10">
                      <v-row>
                        <v-col cols="12">
                          <span class="font-weight-bold">¿Qué configuración de pagos tendrá tu evento?</span>
                        </v-col>
                        <v-col cols="12">
                          <span>Activa o desactiva las diferentes opciones, según
                            apliquen para tu proyecto.</span>
                        </v-col>
                        <v-col cols="12">
                          <v-row class="mx-1 mb-3">
                            <v-col cols="12" class="pb-0">
                              <v-row>
                                <v-switch color="primary" v-model="form.paymentModality" label="¿El evento es gratuito?"
                                  @change="
                                    form.paymentModality
                                      ? (form.installments = false)
                                      : ''
                                    "></v-switch>
                                <v-tooltip top max-width="350px">
                                  <template v-slot:activator="{ on }">
                                    <v-icon color="primary" class="ml-2" dark v-on="on">mdi-help-circle</v-icon>
                                  </template>
                                  <span>Activa esta opción si tu evento es
                                    gratuito.</span>
                                </v-tooltip>
                              </v-row>
                            </v-col>
                            <v-col cols="12" class="pb-0" v-if="form.paymentModality">
                              <v-row>
                                <v-switch label="¿Es un evento por invitación?" color="primary"
                                  v-model="form.invitationalEvent"></v-switch>
                              </v-row>
                            </v-col>
                            <v-col cols="12" class="pb-0" v-if="!form.paymentModality">
                              <v-row>
                                <v-switch label="¿Se aceptan meses sin intereses?" color="primary"
                                  v-model="form.installments"></v-switch>
                              </v-row>
                              <p v-if="form.installments" class="body-2">
                                Aplica sobretasa al ofrecer MSI
                                <small>
                                  <a href="https://stripe.com/es-mx/pricing/local-payment-methods" target="_blank">Más
                                    información</a>
                                </small>
                              </p>
                            </v-col>
                            <v-col cols="12" class="pb-0" v-if="!form.paymentModality">
                              <v-row>
                                <v-switch label="¿Ofrecerás la opción de facturar?" color="primary"
                                  v-model="form.offerBilling"></v-switch>
                                <v-tooltip top max-width="350px">
                                  <template v-slot:activator="{ on }">
                                    <v-icon color="primary" class="ml-2" dark v-on="on">mdi-help-circle</v-icon>
                                  </template>
                                  <span>Al activar esta función, habilitas los
                                    campos para que las personas que requieran
                                    factura puedan dejar sus datos, mismos que
                                    podrás consultar desde tu tablero. Recuerda
                                    que quien factura eres tú como organizador
                                    del evento.</span>
                                </v-tooltip>
                              </v-row>
                            </v-col>
                            <v-col cols="12" class="pb-0">
                              <v-row>
                                <v-switch label="¿Limitarás el numero de boletos que se pueden comprar por persona?"
                                  color="primary" v-model="limitPurchase"></v-switch>
                              </v-row>
                              <v-row>
                                <v-text-field v-if="limitPurchase" v-model="form.ticketsPerOrder"
                                  label="Limite de boletos por persona" type="number" min="1" max="100" filled dense
                                  class="mt-0" :rules="ticketsPerOrderRules"></v-text-field>
                              </v-row>
                            </v-col>
                            <!--Se deshabilita el traslado de la comisión a los asistentes-->
                            <!--
                            <v-col
                              cols="12"
                              class="pb-0"
                              v-if="!form.paymentModality"
                            >
                              <v-row>
                                <v-switch
                                  label="¿Deseas trasladar la comisión de la pasarela de pago al asistente?"
                                  color="primary"
                                  v-model="form.comissionTransfer"
                                ></v-switch>
                                <v-tooltip top max-width="350px">
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      color="primary"
                                      class="ml-2"
                                      dark
                                      v-on="on"
                                      >mdi-help-circle</v-icon
                                    >
                                  </template>
                                  <span
                                    >Al activar esta opción se le aumentará la
                                    comisión de la pasarela de pago al total de
                                    la orden del asistente. Cada opción de pago
                                    tiene diferentes comisiones.</span
                                  >
                                </v-tooltip>
                              </v-row>
                            </v-col>-->
                          </v-row>
                        </v-col>
                      </v-row>
                    </fieldset>
                    <!-- Módulo eliminado - creación de boletos -->
                    <fieldset v-if="false">
                      <v-col>
                        <v-row>
                          <span class="font-weight-bold">Ahora configuremos los tipos de entradas que deseas
                            ofrecer</span>
                        </v-row>
                        <v-row>
                          <span>Recuerda que puedes configurar hasta (x)
                            cantidad.</span>
                        </v-row>
                      </v-col>
                      <v-row class="align-center">
                        <v-col cols="12" class="d-flex justify-end mt-3">
                          <v-btn v-if="form.tickets.length > 1" color="red" fab small @click="deleteTicket()"><v-icon
                              color="white">mdi-close</v-icon></v-btn>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                          <p class="accent--text font-weight-bold mb-0">
                            ENTRADA {{ ticketCounter + 1 }}
                          </p>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-end">
                          <!--<v-sheet
                            rounded
                            class="px-2 py-6"
                            color="wLightAccent"
                          >
                            <span
                              class="
                                orientation--text
                                accent--text
                                font-weight-bold
                              "
                              >ENTRADA {{ ticketCounter + 1 }}</span
                            >
                          </v-sheet>
                          <br />-->
                          <v-btn v-if="form.tickets.length == ticketCounter + 1 &&
                            form.tickets.length > 1 &&
                            ticketCounter > 100
                          " class="mx-2" rounded max-height="60" max-width="15" min-height="50" min-width="10" dark
                            small color="primary" @click="deleteTicket()">
                            <v-icon dark> mdi-minus </v-icon>
                          </v-btn>

                          <v-tooltip v-else-if="ticketCounter > 0 && form.tickets.length > 1
                          " top max-width="250">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mx-2" rounded max-height="60" max-width="15" min-height="50" min-width="10"
                                dark small v-bind="attrs" v-on="on" color="primary" @click="prevTicket()">
                                <v-icon dark> mdi-chevron-left </v-icon>
                              </v-btn>
                            </template>
                            <span v-if="validCreateEventForm">Acceso anterior</span>
                            <span v-else>Verifica que hayas llenado los datos
                              correctamente para poder retroceder</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="8">
                          <v-row>
                            <v-col cols="12" md="8" class="py-0">
                              <v-text-field v-model="form.tickets[ticketCounter].name" label="Nombre" filled rounded
                                :rules="ticketNameRules"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="py-0">
                              <v-text-field v-model="form.tickets[ticketCounter].price" label="precio" type="number"
                                prefix="$" filled rounded :rules="form.paymentModality ? [] : ticketPriceRules
                                  " min="0" :disabled="form.paymentModality"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="form.eventModality == 'HYBRID'" class="py-0">
                              <v-select v-model="form.tickets[ticketCounter].accessType" :items="[
                                modalityCategories[0],
                                modalityCategories[1],
                              ]" label="Modalidad" filled rounded :rules="modalityRules">
                                <!-- Template for render selected data -->
                                <template slot="selection" slot-scope="data">
                                  <span>{{ data.item.item }}</span>
                                </template>
                                <template slot="item" slot-scope="data">
                                  <span>{{ data.item.item }}</span>
                                </template>
                              </v-select>
                              <v-tooltip top max-width="300px">
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" dark v-on="on">mdi-help-circle</v-icon>
                                </template>
                                <p class="ma-0">
                                  El la modalidad sirve para saber lo que
                                  recibirán tus asistentes al finalizar su
                                  registro.
                                </p>
                                <p class="ma-0">En sitio (Código QR).</p>
                                <p class="ma-0">
                                  En línea (Liga de acceso a evento en línea).
                                </p>
                                <p class="ma-0">
                                  Híbrido (Código QR y liga de acceso).
                                </p>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="12" class="py-0">
                              <v-text-field v-model="form.tickets[ticketCounter].details"
                                label="Detalles (Qué incluye el boleto)" filled rounded></v-text-field>
                            </v-col>

                            <v-switch v-model="form.tickets[ticketCounter].hidAccess"
                              label="No mostrar los accesos disponibles" color="primary" :input-value="form.tickets[ticketCounter].hidAccess
                                "></v-switch>
                          </v-row>
                          <v-row v-if="!form.paymentModality">
                            <v-col cols="12" class="pb-0">
                              <p class="center--text font-weight-bold">
                                Datos fiscales
                                <v-menu open-on-hover top offset-y nudge-left="200" max-width="350">
                                  <template #activator="{ on, attrs }">
                                    <v-icon color="primary" dark v-on="on" v-bind="attrs" size="23">
                                      fas fa-question-circle
                                    </v-icon>
                                  </template>
                                  <v-list>
                                    <v-list-item>
                                      <span class="text-justify">
                                        Si tienes habilitada la facturación para
                                        este evento es necesario ingresar los
                                        datos fiscales de cada acceso como si
                                        fueran diferentes productos. &nbsp;
                                        <button class="font-weight-bold cursor" @click="showDialog = true"
                                          style="color: #feb300">
                                          ver ejemplo...
                                        </button></span>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </p>

                              <v-dialog v-model="showDialog" width="700" @click:outside="showDialog = false">
                                <v-card class="pa-10">
                                  <p class="subtitle-1 font-weight-bold text-center mb-7">
                                    Datos fiscales de acceso
                                  </p>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-text-field label="Clave de unidad" filled rounded value="E48" disabled>
                                      </v-text-field>
                                      <v-text-field :items="modalityCategories" label="Nombre de unidad" filled rounded
                                        value="Servicio" disabled>
                                      </v-text-field>
                                      <v-text-field label="Clave de producto" type="number" filled rounded
                                        value="80141607" disabled>
                                      </v-text-field>
                                      <v-textarea label="Descripción o concepto" filled rounded disabled
                                        value="Boleto General al congreso nacional de arquitectura">
                                      </v-textarea>
                                    </v-col>
                                  </v-row>
                                  <v-card-actions class="d-flex justify-center">
                                    <action-button @click="showDialog = false">
                                      Cerrar
                                    </action-button>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                          <!--form.offerBilling-->
                          <v-row v-if="!form.paymentModality">
                            <v-col cols="12" md="6" class="py-0">
                              <v-text-field v-model="form.tickets[ticketCounter].billingInformation
                                .productKey
                                " label="Clave de producto" filled rounded :rules="form.offerBilling ? productKeyRules : []
                                  " :disabled="!form.offerBilling"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                              <v-text-field v-model="form.tickets[ticketCounter].billingInformation
                                .unitKey
                                " label="Clave de unidad" filled rounded :rules="form.offerBilling ? unitKeyRules : []"
                                :disabled="!form.offerBilling" @keyup="
                                  form.tickets[
                                    ticketCounter
                                  ].billingInformation.unitKey =
                                  form.tickets[
                                    ticketCounter
                                  ].billingInformation.unitKey.toUpperCase()
                                  "></v-text-field>
                            </v-col>
                            <v-col cols="12" class="py-0">
                              <v-text-field v-model="form.tickets[ticketCounter].billingInformation
                                .unitName
                                " label="Nombre de unidad" filled rounded
                                :rules="form.offerBilling ? unitNameRules : []" :disabled="!form.offerBilling" @keyup="
                                  form.tickets[
                                    ticketCounter
                                  ].billingInformation.unitName =
                                  form.tickets[
                                    ticketCounter
                                  ].billingInformation.unitName.toUpperCase()
                                  "></v-text-field>
                            </v-col>

                            <!--Alerta-->
                            <!-- ...otros elementos del template... -->
                            <v-col cols="12">
                              <v-row>
                                <!-- Vuetify switch -->
                                <v-switch v-model="form.tickets[ticketCounter]
                                  .billingInformation.hasIva
                                  " label="Deseo activar la facturación exenta de IVA" color="primary"
                                  @change="handleSwitchChange(ticketCounter)" :input-value="!form.tickets[ticketCounter]
                                    .billingInformation.hasIva
                                    "></v-switch>

                                <!---->

                                <!-- Confirmation dialog -->

                                <v-dialog v-model="showConfirmationDialog" max-width="600px" v-col cols="2"
                                  class="pt-10 d-flex align-center justify-center" persistent>
                                  <v-card>
                                    <v-card-title class="title text-left" style="word-break: break-word">
                                      ¿Estás seguro de que las facturas de este
                                      boleto deben generarse exentas de IVA?
                                    </v-card-title>

                                    <v-card-actions>
                                      <v-btn v-bind="attrs" v-on="on" color="accent" class="rounded-xl"
                                        :small="$vuetify.breakpoint.xsOnly" @click="confirmExemption()">
                                        <span class="font-weight-bold black--text">CONFIRMAR</span>
                                      </v-btn>

                                      <v-btn v-bind="attrs" v-on="on" color="white" class="rounded-xl"
                                        :small="$vuetify.breakpoint.xsOnly" @click="cancelExemption(ticketCounter)">
                                        <span class="font-weight-bold black--text">CANCELAR</span>
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-row>
                            </v-col>

                            <!---->

                            <v-col cols="12" class="py-0">
                              <v-textarea v-model="form.tickets[ticketCounter].billingInformation
                                .description
                                " label="Descripción o concepto" filled rounded :rules="form.offerBilling
                                  ? billingDescriptionRules
                                  : []
                                  " :disabled="!form.offerBilling"></v-textarea>
                            </v-col>
                          </v-row>
                          <!--<v-list>
                            <v-list-item>
                              <v-text-field
                                v-model="form.tickets[ticketCounter].name"
                                label="Nombre"
                                filled
                                rounded
                                :rules="ticketNameRules"
                              ></v-text-field>
                            </v-list-item>
                            <v-list-item v-if="form.eventModality == 'HYBRID'">
                              <v-select
                                v-model="form.tickets[ticketCounter].accessType"
                                :items="modalityCategories"
                                label="Modalidad"
                                filled
                                rounded
                                :rules="modalityRules"
                              >
                                <template slot="selection" slot-scope="data">
                                  <span>{{ data.item.item }}</span>
                                </template>
                                <template slot="item" slot-scope="data">
                                  <span>{{ data.item.item }}</span>
                                </template>
                              </v-select>
                              <v-tooltip top max-width="300px">
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" dark v-on="on"
                                    >mdi-help-circle</v-icon
                                  >
                                </template>
                                <p class="ma-0">
                                  El la modalidad sirve para saber lo que
                                  recibirán tus asistentes al finalizar su
                                  registro.
                                </p>
                                <p class="ma-0">En sitio (Código QR).</p>
                                <p class="ma-0">
                                  En línea (Liga de acceso a evento en línea).
                                </p>
                                <p class="ma-0">
                                  Híbrido (Código QR y liga de acceso).
                                </p>
                              </v-tooltip>
                            </v-list-item>
                            <v-list-item v-if="!form.paymentModality">
                              <v-text-field
                                v-model="form.tickets[ticketCounter].price"
                                label="precio"
                                type="number"
                                prefix="$"
                                filled
                                rounded
                                :rules="ticketPriceRules"
                                min="0"
                              ></v-text-field>
                            </v-list-item>
                            <v-list-item>
                              <v-text-field
                                v-model="form.tickets[ticketCounter].details"
                                label="Detalles (Qué incluye el boleto)"
                                filled
                                rounded
                              ></v-text-field>
                            </v-list-item>
                          </v-list>-->
                        </v-col>
                        <v-col cols="2">
                          <v-tooltip v-if="form.tickets.length == ticketCounter + 1" top max-width="250">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mx-2" rounded max-height="60" max-width="15" min-height="50" min-width="10"
                                dark small v-bind="attrs" v-on="on" color="primary" @click="addTicket()">
                                <v-icon dark> mdi-plus </v-icon>
                              </v-btn>
                            </template>
                            <span v-if="validCreateEventForm">Agregar acceso</span>
                            <span v-else>Verifica que hayas llenado los datos
                              correctamente para poder agregar un acceso</span>
                          </v-tooltip>

                          <v-tooltip v-else top max-width="250">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mx-2" rounded max-height="60" max-width="15" min-height="50" min-width="10"
                                dark small v-bind="attrs" v-on="on" color="primary" @click="nextTicket()">
                                <v-icon dark> mdi-chevron-right </v-icon>
                              </v-btn>
                            </template>
                            <span v-if="validCreateEventForm">Siguiente acceso</span>
                            <span v-else>Verifica que hayas llenado los datos
                              correctamente para poder avanzar</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </fieldset>
                    <!-- Campos Exclusivos de imef -->
                    <fieldset v-if="isImefOrganization(user._id, user.organization) && step === 11">
                      <v-row class="mx-12">
                        <v-col cols="12">
                          <span class="font-weight-bold">
                            Configurar datos fiscales del evento
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <v-select label="Grupo" rounded dense filled v-model="imefForm['group']"
                            :items="formatGroupsItems(IMEF_GROUPS)" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field label="Almacén" rounded dense :rules="[
                            (v) =>
                              v.length <= 15 ||
                              'El código de almacén debe ser 15 caracteres máximo',
                          ]" filled v-model="imefForm['almacen']" />
                          <!-- Add rules: up to 15 characters -->
                        </v-col>
                        <v-col cols="12">
                          <v-select :items="formatServiceItems(IMEF_SERVICES)" v-model="imefForm['eventCode']"
                            label="Código del evento" rounded dense filled />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field label="Serie" rounded dense filled v-model="imefForm['serie']" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field label="Segmento Contable" rounded dense filled
                            v-model="imefForm['accountingSegment']" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field label="Lugar de expedición" rounded dense filled
                            v-model="imefForm['zipCode']" />
                        </v-col>
                      </v-row>
                    </fieldset>
                    <fieldset v-if="(!isImefOrganization(user._id, user.organization) && step === 11) ||
                      step == 12
                    ">
                      <v-row>
                        <v-col cols="12">
                          <span class="font-weight-bold">Último paso de la creación del evento.</span>
                        </v-col>
                        <v-col cols="12">
                          <span>Has finalizado la creación de tu evento da clic en
                            el botón de continuar.</span>
                        </v-col>
                        <v-col class="d-flex justify-center">
                          <v-btn class="font-weight-bold" color="secondary" @click="_createEvent()" rounded
                            elevation="0" :loading="createEventButtonLoader">Continuar</v-btn>
                        </v-col>
                      </v-row>
                    </fieldset>
                    <v-row class="mt-3 mb-8" justify="space-between">
                      <v-col cols="12" class="d-flex justify-space-between">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="step == 1" class="mx-2" fab small color="accent" v-bind="attrs" v-on="on"
                              @click="prev()">
                              <v-icon dark> mdi-chevron-left </v-icon>
                            </v-btn>
                          </template>
                          <span>Regresar</span>
                        </v-tooltip>
                        <!--!validCreateEventForm || -->
                        <v-tooltip top max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="(!isImefOrganization(user._id, user.organization) &&
                              step === 11) ||
                              step == 12
                              " class="mx-2" fab small color="accent" v-bind="attrs" v-on="on" @click="next()">
                              <v-icon dark> mdi-chevron-right </v-icon>
                            </v-btn>
                          </template>
                          <span v-if="validCreateEventForm">Siguiente</span>
                          <span v-else>Verifica que hayas llenado los datos correctamente
                            para poder avanzar</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row :class="step === 3 ? '' : 'pt-16'">
                      <v-col cols="12" :class="step === 3 ? '' : 'pt-md-12'">
                        <v-row>
                          <v-progress-linear color="accent" :value="progress"></v-progress-linear>
                        </v-row>
                        <v-row justify="center">
                          <span class="font-weight-bold">{{
                            `${step} de ${returnTotalSteps()}`
                            }}</span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>
<script>
import PersonalizedDialog from '@/components/shared/PersonalizedDialog.vue';
import axios from 'axios';
import { validations } from '@/share/validations.js';
import { Base64 } from 'js-base64';
import { mapState, mapMutations, mapActions } from 'vuex';
import {
  IMEF_GROUPS,
  IMEF_SERVICES,
  formatGroupsItems,
  formatServiceItems,
  getAccountingSegments,
  isImefOrganization,
} from '../../share/imef';
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from 'tiptap-vuetify';
import path from 'path';
import { get } from 'http';
export default {
  components: {
    PersonalizedDialog,
    TiptapVuetify,
  },
  data() {
    return {
      IMEF_GROUPS,
      IMEF_SERVICES,
      selectedSubgroup: 'Enviar a Todos',
      subgroups: ['Enviar a Todos'],
      selectedOption: 'no',
      confirmOption: false,
      confirmDialog2: false,
      manualAddressEntry: false,
      manualAddress: '',
      showConfirmationDialog: false,
      showAlert: true,
      switchValue: false,
      switchHidAccess: false,
      previousTags: [],
      limitPurchase: false,
      validCreateEventForm: false,
      showDialog: false,
      imefForm: {
        group: '',
        almacen: '',
        eventCode: '',
        serie: '',
        accountingSegment: '',
        zipCode: '',
      },
      form: {
        name: "",
        description: "",
        eventCode: "",
        dateConfiguration: {
          startDate: '',
          endDate: '',
          startTime: '',
          endTime: '',
          timeZone: '',
        },
        ticketsPerOrder: 0,
        recurrent: false,
        eventModality: '',
        type: '',
        category: '',
        micrositeConfiguration: {
          link: '',
          primaryColor: '#f0f0f0',
          secondaryColor: '#000000',
          buttonColor: '#000000',
          textButtonColor: 'white',
        },
        presentialEventConfiguration: {},
        virtualEventConfiguration: {},
        evaluationConfiguration: {
          hasEvaluationUrl: false,
          url: null,
        },
        amenities: [],
        tags: [],
        paymentModality: false,
        invitationalEvent: false,
        installments: false,
        offerBilling: false,
        comissionTransfer: false,
        tickets: [
          {
            name: '',
            accessType: '',
            inventoryType: 'FINITE',
            price: '',
            quantity: '',
            details: '',
            hidAccess: false,

            billingInformation: {
              unitKey: '',
              unitName: '',
              productKey: '',
              description: '',
              hasIva: true,
            },
          },
        ],
        organization: '',
      },
      createEventButtonLoader: false,
      step: 1,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      dateMenu1: false,
      startDateRules: validations.requiredFValidation(
        'fecha de inicio del evento'
      ),
      dateMenu2: false,
      timeMenu1: false,
      startTimeRules: validations.requiredFValidation(
        'hora de inicio del evento'
      ),
      timeMenu2: false,
      // Datos de el select de husos horarios
      timeZones: [
        'Tiempo del Sureste UTC -5',
        'Tiempo del Centro UTC -6',
        'Tiempo del Pacífico UTC -7',
        'Tiempo del Noroeste UTC -8',
      ],
      // Paso 4
      modalityCategories: [
        {
          item: 'Presencial',
          value: 'PRESENTIAL',
        },
        {
          item: 'En línea',
          value: 'VIRTUAL',
        },
        {
          item: 'Híbrido',
          value: 'HYBRID',
        },
      ],
      // Paso 5
      eventType: [
        'Curso / Taller ',
        'Foro / Congreso / Expo',
        'Conferencia',
        'Diplomado / Posgrado',
        'Otro',
      ],
      eventCategory: [
        'Académico',
        'Arte',
        'Bienestar',
        'Capacitación',
        'Obra benéfica',
        'Cine',
        'Gastronomía',
        'Concierto',
        'Cultura',
        'Deporte',
        'Emprendimiento',
        'Empresarial',
        'Política',
        'Publicidad',
        'Religión',
        'Social',
        'Otro',
      ],
      // Paso 6
      place: null,
      // Paso 7
      /*amenities: [
        "Estacionamiento",
        "Coffee break",
        "Alimentos",
        "Aire acondicionado",
        "Constancia",
        "Cóctel",
        "Valet Parking",
        "Zona Comercial",
      ],*/
      amenities: [
        {
          name: 'Estacionamiento',
          eventType: 'PRESENTIAL',
        },
        {
          name: 'Coffee break',
          eventType: 'PRESENTIAL',
        },
        {
          name: 'Alimentos',
          eventType: 'PRESENTIAL',
        },
        {
          name: 'Aire acondicionado',
          eventType: 'PRESENTIAL',
        },
        {
          name: 'Constancia',
          eventType: 'HYBRID',
        },
        {
          name: 'Cóctel',
          eventType: 'PRESENTIAL',
        },
        {
          name: 'Valet parking',
          eventType: 'PRESENTIAL',
        },
        {
          name: 'Zona comercial',
          eventType: 'PRESENTIAL',
        },
        {
          name: 'Hospedaje',
          eventType: 'PRESENTIAL',
        },
      ],
      // Paso 9
      ticketCounter: 0,
      // Validations
      eventNameRules: validations.generalMValidation({
        name: 'nombre del evento',
        required: true,
      }),
      linkRules: validations.generalMValidation({
        name: 'liga de acceso',
        required: true,
      }),
      modalityRules: validations.generalFValidation({
        name: 'modalidad',
        required: true,
      }),
      ticketsPerOrderRules: validations.generalMValidation({
        required: true,
        name: 'limite de boletos por persona',
      }),
      eventTypeRules: validations.requiredMValidation('tipo'),
      eventCategoryRules: validations.requiredFValidation('categoría'),
      endDateRules: validations.requiredFValidation('fecha de fin del evento'),
      endTimeRules: validations.requiredFValidation('hora de fin del evento'),
      countryRules: validations.requiredMValidation('país'),
      cityRules: validations.requiredFValidation('ciudad'),
      ticketNameRules: validations.requiredMValidation('nombre de boleto'),
      modalityRules: validations.requiredFValidation('modalidad'),
      ticketPriceRules: validations.requiredMValidation('precio de boleto'),
      quantityRules: validations.numberFValidation({
        name: 'cantidad disponible',
        required: true,
      }),
      // Reglas de formulario de información fiscal
      unitNameRules: validations.generalMValidation({
        required: true,
        name: 'nombre de unidad',
      }),
      unitKeyRules: validations.generalFValidation({
        required: true,
        name: 'clave de unidad',
        maxLength: 4,
      }),
      productKeyRules: validations.generalFValidation({
        required: true,
        name: 'clave de producto',
        minLength: 8,
        maxLength: 8,
      }),
      billingDescriptionRules: validations.generalFValidation({
        name: 'descripción',
        required: true,
      }),
      localTimeZone: ''
    };
  },
  async created() {
    this.form.organization = this.user._id;
    this.localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.getTags();
    await this.fetchSubgroups();
  },
  methods: {
    ...mapActions('event', ['createEvent']),
    ...mapMutations('loader', ['loading', 'loaded']),
    ...mapMutations('notification', ['show']),
    formatGroupsItems,
    formatServiceItems,
    getAccountingSegments,
    isImefOrganization,
    handleSwitchChange(index) { },
    async fetchSubgroups() {
      try {
        const response = await axios.get('/users/subgroups');

        this.subgroups = ['Enviar a Todos', ...response.data];
      } catch (error) {
        console.error('Error al obtener los subgrupos:', error);
      }
    },
    async fetchEmails(selectedSubgroup) {
      try {
        const response = await axios.get(
          `/users/emails?subgroup=${selectedSubgroup}`
        );
        return response.data;
      } catch (error) {
        console.error('Error fetching emails:', error);
        this.show({
          color: 'error',
          text: 'Error al obtener los correos electrónicos.',
        });
        return [];
      }
    },

    handleSwitchChangeAccess(index) {
      this.switchHidAccess =
        this.form.tickets[index].billingInformation.hidAccess;
    },
    //   isValidStep6() {
    //     if (form.eventModality != 'PRESENTIAL' && form.virtualEventConfiguration.url != null)
    //     {
    //       return true
    //     }
    //     else{return false}
    // },
    onOptionChange() {
      this.selectedOption = 'yes';
      if (this.selectedOption === 'yes') {
        this.confirmDialog2 = true;
      } else {
        this.selectedSubgroup = '';
      }
    },
    confirmYes() {
      this.confirmDialog2 = false;
      this.selectedOption = 'yes';
      this.confirmOption = true;
    },
    confirmNo() {
      if (this.confirmOption == true) {
        this.confirmDialog2 = false;
      } else {
        this.selectedOption = 'no';
        this.confirmDialog2 = false;
      }
    },

    resetFields() {
      this.form.presentialEventConfiguration.country = '';
      this.form.presentialEventConfiguration.city = '';
      this.manualAddress = '';
      this.place = null;
    },
    validateFields() {
      this.validCreateEventForm =
        this.form.presentialEventConfiguration.country.trim() !== '' &&
        this.form.presentialEventConfiguration.city.trim() !== '' &&
        (this.manualAddressEntry ? this.manualAddress.trim() !== '' : true);
    },

    handleSwitchChange(index) {
      this.form.tickets[index].billingInformation.hasIva =
        !this.form.tickets[index].billingInformation.hasIva;
      this.switchValue = this.form.tickets[index].billingInformation.hasIva;
      this.showConfirmationDialog = this.switchValue;
    },

    confirmExemption() {
      // If the user confirms, close the dialog and enable the switch
      this.showConfirmationDialog = false;
      this.form.tickets[this.ticketCounter].billingInformation.hasIva = true;
      this.switchValue = true;
    },

    cancelExemption(index) {
      // If the user cancels, close the dialog and visually uncheck the switch
      this.showConfirmationDialog = false;
      this.form.tickets[index].billingInformation.hasIva = false;
      this.switchValue = false;
    },

    async getTags() {
      const response = await this.axios({
        method: 'GET',
        url: 'events/tags',
      });

      console.log(response);

      if (response.status == 200) {
        this.previousTags = response.data.data.tags;
      } else {
        this.show({
          text: 'Hubo un problema al obtener los tags',
          color: 'error',
        });
      }
    },
    uppercase() {
      this.affiliation.billingInformation.unitKey =
        this.affiliation.billingInformation.unitKey.toUpperCase();
    },
    switchHasIva(index) {
      this.form.tickets[index].billingInformation.hasIva =
        !this.form.tickets[index].billingInformation.hasIva;
    },
    /**
     * Función para validar los campos del la creación del evento
     */
    validateCreateEventForm() {
      if (this.$refs.createEventForm.validate()) {
        this.snackbar = true;
        return true;
      }
      return false;
    },
    async _createEvent() {
      this.createEventButtonLoader = true;
      this.loading();

      try {
        this.form.description = Base64.encode(this.form.description);
        this.form.dateConfiguration.timeZone = this.localTimeZone;
        // Attempt to send notification if required
        if (this.selectedOption === 'yes') {
          try {
            const emails = await this.fetchEmails(this.selectedSubgroup);
            await this.axios.post('/users/send-event-notification', {
              eventTitle: this.form.name,
              emails: emails,
            });
          } catch (error) {
            this.show({
              color: 'error',
              text:
                error.response.data ||
                'Limite de notificacion por dia alcanzado.',
            });
            return;
          }
        }
        this.form['imefForm'] = this.imefForm;

        let response = await this.createEvent(this.form);
        console.log('respuesta al crear evento');
        console.log(response);
        if (response.status == 200) {
          this.show({
            text: 'Evento creado exitosamente',
            color: 'primary',
          });
          this.$router.push({
            name: 'micrositio',
            params: {
              eventId: response.data._id,
              showTutorial: true,
            },
          });
        }
      } catch (error) {
        console.log(error);
        this.show({
          text: 'Hubo un problema al crear el evento',
          color: 'error',
        });
      } finally {
        this.createEventButtonLoader = false;
        this.loaded();
      }
    },

    prev() {
      this.step--;
      // if (this.step == 10 && this.form.invitationalEvent) {
      //   this.step--;
      // }
    },
    next() {
      if (this.validateCreateEventForm()) {
        this.step++;
      }
      // La validación para el paso 6 se hace diferente, debido a que las validaciones de campos de vuetify no se puede hacer en el componente de "gmap-autocomplete"
      // if (this.step == 7) {
      //   this.step++;
      //   // Reiniciar validación del formulario
      //   this.$refs.createEventForm.resetValidation();
      //   if (this.form.eventModality == "VIRTUAL") {
      //     this.step++;
      //     // Reiniciar validación del formulario
      //     this.$refs.createEventForm.resetValidation();
      //   } else {
      //     this.validCreateEventForm = false;
      //   }
      // };
      // if (this.step == 11 && !this.isImefOrganization(this.user._id)) {
      //   this.step = 12;
      // };
      // if (this.step == 9 && this.form.invitationalEvent) {
      //   this.step = 10;
      // };
      // if (this.validateCreateEventForm() && this.step != 7) {
      //   this.step++;
      //   // Reiniciar validación del formulario
      //   this.$refs.createEventForm.resetValidation();
      // }
    },
    submit() {
      alert('Form Is Submitted.');
    },
    minusTicket() {
      alert('Minus ticket.');
    },
    plusTicket() {
      alert('Plus ticket.');
    },
    /**
     * Función para dar formato de fecha YYYY-MM-DD.
     * @param {String} date Fecha inicial o final del evento.
     */
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    updateTicketModality() {
      if (this.form.eventModality == 'VIRTUAL') {
        this.form.tickets[0].accessType = 'VIRTUAL';
      } else if (this.form.eventModality == 'PRESENTIAL') {
        this.form.tickets[0].accessType = 'PRESENTIAL';
      } else {
        this.form.tickets[0].accessType = 'HYBRID';
      }
    },
    saveAddress(place) {
      let {
        geometry: {
          location: { lat },
        },
        geometry: {
          location: { lng },
        },
        formatted_address,
        name,
      } = place;
      let flag = isNaN(place.address_components.slice(-1)[0].short_name)
        ? true
        : false;
      let components = [];
      if (flag) components = [...place.address_components].slice(-3);
      else components = [...place.address_components].slice(-4);

      this.form.presentialEventConfiguration = {
        latitude: lat(),
        longitude: lng(),
        formattedAddress: formatted_address,
        name,
        city: components[0].long_name,
        state: components[1].short_name,
        country: components[2].long_name,
      };
      this.place = formatted_address;
    },
    /*Agregar ticket */
    addTicket() {
      if (this.validateCreateEventForm()) {
        if (this.form.eventModality == 'PRESENTIAL') {
          this.form.tickets.push({
            name: '',
            accessType: 'PRESENTIAL',
            inventoryType: 'FINITE',
            price: 0,
            quantity: '',
            details: '',
            billingInformation: {
              unitKey: '',
              unitName: '',
              productKey: '',
              description: '',
              hasIva: false,
            },
          });
          this.ticketCounter++;
        } else if (this.form.eventModality == 'VIRTUAL') {
          this.form.tickets.push({
            name: '',
            accessType: 'VIRTUAL',
            inventoryType: 'FINITE',
            price: 0,
            quantity: '',
            details: '',
            billingInformation: {
              unitKey: '',
              unitName: '',
              productKey: '',
              description: '',
              hasIva: false,
            },
          });
          this.ticketCounter++;
        } else {
          this.form.tickets.push({
            name: '',
            accessType: '',
            inventoryType: 'FINITE',
            price: 0,
            quantity: '',
            details: '',
            billingInformation: {
              unitKey: '',
              unitName: '',
              productKey: '',
              description: '',
            },
          });
          this.ticketCounter++;
        }
        this.$refs.createEventForm.resetValidation();
      }
    },
    /*Eliminar ticket*/
    deleteTicket() {
      let auxTicketCounter = this.ticketCounter;
      if (this.ticketCounter == 1) {
        this.ticketCounter = 0;
      } else if (this.ticketCounter > 1) {
        this.ticketCounter--;
      }
      this.form.tickets.splice(auxTicketCounter, 1);
    },
    prevTicket() {
      if (this.validateCreateEventForm()) {
        this.ticketCounter--;
      }
    },
    nextTicket() {
      if (this.validateCreateEventForm()) {
        this.ticketCounter++;
      }
    },
    returnTotalSteps() {
      if (this.isImefOrganization(this.user._id, this.user.organization)) {
        return 12;
      }
      return 11;
    },
  },

  watch: {
    'imefForm.group': function () {
      this.imefForm.zipCode = this.imefForm.group.zipCode;
      this.imefForm.serie = this.imefForm.group.Serie;
      this.imefForm.accountingSegment = this.imefForm.group.segment;
    },
    'form.paymentModality': function (val) {
      if (val) {
        this.form.installments = false;
        this.form.offerBilling = false;
        this.form.tickets.forEach((element) => {
          element.price = 0;
        });
      }
    },
    step() {
      this.$refs.createEventForm.resetValidation();
    },
  },

  computed: {
    ...mapState('user', ['user']),
    progress() {
      const totalSteps = this.isImefOrganization(this.user._id, this.user.organization) ? 12 : 11;
      return (this.step * 100) / totalSteps;
    },
    /**
     * Función para tomar la fecha actual y ponerla en los v-date-picker.
     */
    todayDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    /**
     * Función para dar formato de la fecha inicial del evento.
     */
    initialDateFormatted() {
      return this.formatDate(
        this.form.dateConfiguration.startDate.substring(0, 10)
      );
    },
    /**
     * Función para dar formate de la fecha final del evento.
     */
    finalDateFormatted() {
      return this.formatDate(
        this.form.dateConfiguration.endDate.substring(0, 10)
      );
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}

.line {
  transform: translateY(-235%);
}

fieldset {
  border: none;
  padding: 0;
}

/* @media (min-width: 959px) {
        .align--transform{
            position:absolute;
            top:30%;
            left:50%;
            bottom: 50%;
            height:350px;
            width:400px;
            margin-top:-100px;
            margin-left:-150px;
        }
    } */
.orientation--text {
  writing-mode: vertical-lr;
  text-orientation: mixed;
}

.v-text-field.v-text-field--solo .v-input__append-outer,
.v-text-field.v-text-field--solo .v-input__prepend-outer {
  margin-top: 4px !important;
}
</style>
